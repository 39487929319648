import { ReactNode } from 'react';
import { NextPage } from 'next';

import Navbar from 'components/home/Navbar';
// import Footer from 'components/home/Footer';

interface Props {
  domainName: string;
  children: ReactNode;
}

const Layout: NextPage<Props> = ({ domainName, children }) => {
  return (
    <div className="min-h-screen bg-gray-100">
      <Navbar domainName={domainName} />
      {children}
    </div>
  );
};

export default Layout;
