import { useEffect, useLayoutEffect, useRef, useState } from 'react';

import Button from 'components/shared/Button';
import Link from 'next/link';
import { useAuth } from 'hooks/useAuth';
import { useForm } from 'react-hook-form';
import { useRouter } from 'next/router';
import { addToast } from 'hooks/useToast';
// import { auth } from 'config/firebase';
import firebase from 'firebase/app';

interface LoginData {
  email: string;
  password: string;
  verificationCode?: string;
}

const LoginForm: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ shouldUnregister: true });
  const router = useRouter();

  const { user, signIn } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showMultifactorCode, setShowMultifactorCode] = useState(false);
  const [verificationId, setVerificationId] = useState('');
  const [mfaResolver, setMfaResolver] = useState(null);
  const [mfaHint, setMfaHint] = useState({});

  useEffect(() => {
    if (user) {
      if (router.query && router.query.from) {
        router.push(router.query.from as string);
      } else {
        router.push('/dashboard');
      }
    }
  }, [user, router]);

  const recaptchaVerifierRef = useRef(null);

  const onSubmit = (data: LoginData) => {
    setIsLoading(true);
    setError(null);
    const recaptchaVerifier = recaptchaVerifierRef.current;
    if (showMultifactorCode && verificationId && data.verificationCode) {
      const cred = firebase.auth.PhoneAuthProvider.credential(
        verificationId,
        data.verificationCode
      );
      const multiFactorAssertion =
        firebase.auth.PhoneMultiFactorGenerator.assertion(cred);
      return mfaResolver.resolveSignIn(multiFactorAssertion);
    } else {
      recaptchaVerifier.verify().then(() => {
        signIn(data).then(
          (response: {
            error?: { message: string; code?: string; resolver?: any };
          }) => {
            setIsLoading(false);
            if (response?.error) {
              if (response.error?.code == 'auth/multi-factor-auth-required') {
                // The user is a multi-factor user. Second factor challenge is required.
                setShowMultifactorCode(true);
                const resolver = response.error.resolver;
                setMfaResolver(resolver);
                const selectedIndex = 0;

                const phoneInfoOptions = {
                  multiFactorHint: resolver['hints'][selectedIndex],
                  session: resolver['session'],
                };
                console.log(phoneInfoOptions.multiFactorHint);
                setMfaHint(phoneInfoOptions.multiFactorHint);
                const phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
                // Send SMS verification code.
                return phoneAuthProvider
                  .verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier)
                  .then(function (verificationId) {
                    // verificationId will be needed for sign-in completion.
                    setVerificationId(verificationId);
                  })
                  .catch((reason) => {
                    console.log('mfa failed:', reason);
                  });
              } else {
                setError(response.error);
              }
            } else {
              addToast({
                title: 'Welcome back!👋',
                description: 'You are successfully signed in.',
                type: 'success',
              });
            }
          }
        );
      });
    }
  };

  useLayoutEffect(() => {
    if (!recaptchaVerifierRef.current) {
      try {
        recaptchaVerifierRef.current = new firebase.auth.RecaptchaVerifier(
          'recaptcha-container',
          {
            size: 'invisible',
            callback: function (response) {
              console.log('success', response);
            },
            'expired-callback': function () {
              console.log('expired-callback');
            },
          }
        );
      } catch {
        console.log("Captcha couldn't find the container.");
      }
    }
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {(error?.message as string) && (
        <div className="p-2 mb-4 text-center text-red-500 border border-red-600 border-dashed rounded">
          <span>{error.message as string}</span>
        </div>
      )}
      <div className="rounded-md">
        <label
          htmlFor="email"
          className="block text-sm font-medium leading-5 text-gray-700"
        >
          Email address
        </label>
        <div className="mt-1 rounded-md">
          <input
            id="email"
            className="block w-full px-3 py-2 placeholder-gray-400 transition duration-150 ease-in-out border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5"
            type="email"
            name="email"
            {...register('email', {
              required: 'Please enter an email',
              pattern: {
                value:
                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: 'Not a valid email',
              },
            })}
          />
          {errors.email && (
            <div className="mt-2 text-xs text-red-600">
              {errors.email.message as string}
            </div>
          )}
        </div>
      </div>
      <div className="mt-4">
        <label
          htmlFor="password"
          className="block text-sm font-medium leading-5 text-gray-700"
        >
          Password
        </label>
        <div className="mt-1 rounded-md">
          <input
            id="password"
            className="block w-full px-3 py-2 placeholder-gray-400 transition duration-150 ease-in-out border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5"
            type="password"
            name="password"
            {...register('password', {
              required: 'Please enter a password',
              minLength: {
                value: 6,
                message: 'Should have at least 6 characters',
              },
            })}
          />
          {errors.password && (
            <div className="mt-2 text-xs text-red-600">
              {errors.password.message as string}
            </div>
          )}
        </div>
      </div>
      {showMultifactorCode && (
        <div className="mt-4">
          <label
            htmlFor="verificationCode"
            className="block text-sm font-medium leading-5 text-gray-700"
          >
            MFA Code -{' '}
            <abbr
              title={`${mfaHint['displayName']} - ${mfaHint['phoneNumber']}`}
            >
              Hint
            </abbr>
          </label>
          <div className="mt-1 rounded-md">
            <input
              id="verificationCode"
              className="block w-full px-3 py-2 placeholder-gray-400 transition duration-150 ease-in-out border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5"
              type="verificationCode"
              name="verificationCode"
              {...register('verificationCode', {
                required: 'Please enter a verificationCode',
                minLength: {
                  value: 6,
                  message: 'Should have at least 6 characters',
                },
              })}
            />
            {errors.verificationCode && (
              <div className="mt-2 text-xs text-red-600">
                {errors.verificationCode.message as string}
              </div>
            )}
          </div>
        </div>
      )}

      <div className="flex items-end mt-4">
        <div className="text-sm leading-5">
          <Link legacyBehavior href="/reset-password">
            <a
              href="#"
              className="font-medium transition duration-150 ease-in-out text-royal-blue-600 hover:text-royal-blue-500 focus:outline-none focus:underline"
            >
              Forgot your password?
            </a>
          </Link>
        </div>
      </div>

      <div className="mt-4">
        <span className="block w-full rounded-md shadow-sm">
          <Button title="Login" type="submit" isLoading={isLoading}>
            <div id="recaptcha-container"></div>
          </Button>
        </span>
      </div>
    </form>
  );
};

export default LoginForm;
