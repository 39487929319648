import { toast } from 'react-toastify';

// const Msg = ({ title, description, closeToast, toastProps }}) => (
//   <div>
//     {title} {toastProps.position}
//     <button onClick={closeToast}>Close</button>
//   </div>
// );

{
  /* <div
className="flex items-end justify-center px-4 pt-6 pb-3 pointer-events-none sm:px-6 sm:items-start sm:justify-end z-50"
key={toast.id}
>
<Transition
  show={shouldShow}
  enter="transform ease-out duration-300 transition"
  enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
  enterTo="translate-y-0 opacity-100 sm:translate-x-0"
  leave="transition ease-in duration-100"
  leaveFrom="opacity-100"
  leaveTo="opacity-0"
>
  <div className="w-full max-w-sm bg-white rounded-lg shadow-lg pointer-events-auto">
    <div className="overflow-hidden rounded-lg shadow-xs">
      <div className="p-4 w-[300px]">
        <div className="flex items-start">
          <div className="flex-shrink-0">{icon()}</div>
          <div className="ml-3 w-0 flex-1 pt-0.5">
            <p className="text-sm font-medium leading-5 text-gray-900">
              {toast.title}
            </p>
            <p className="mt-1 text-sm leading-5 text-gray-500">
              {toast.description}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</Transition>
</div> */
}

const addToast = ({ title, description, type }) => {
  //   return toast(<Msg title={title} description={description} />, { type });
  toast(title + ' - ' + description, { type });
};

const addCenterToast = ({ title, description, type }) => {
  //   return toast(<Msg title={title} description={description} />, { type });
  toast(title + ' - ' + description, {
    type,
    position: 'bottom-center',
    autoClose: false,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
  });
};

export { addToast, addCenterToast };
