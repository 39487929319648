const domainNameOptions: {
  [key: string]: {
    logoPath: string;
    imagePath: string;
    backgroundColor: string;
    color: string;
    showLandingPage: boolean;
    title: string;
    slogan: string;
    homeMd: string;
  };
} = {
  'erc.software': {
    logoPath: 'favicon',
    imagePath: 'img',
    backgroundColor: '#ffffff',
    color: '#000000',
    showLandingPage: false,
    title: 'ERC Expert',
    slogan: 'Optimized, Fast, Reliable',
    homeMd: 'home.md',
  },
  'software.ercexpert.com': {
    logoPath: 'favicon',
    imagePath: 'img',
    backgroundColor: '#ffffff',
    color: '#000000',
    showLandingPage: false,
    title: 'ERC Expert',
    slogan: 'Optimized, Fast, Reliable',
    homeMd: 'home.md',
  },
  'brightdayerc.com': {
    logoPath: 'favicon-brightdayerc',
    imagePath: 'img',
    backgroundColor: '#ffffff',
    color: '#000000',
    showLandingPage: true,
    title: 'Bright Day ERC',
    slogan: 'Optimized, Fast, Reliable',
    homeMd: 'home-brightDayErc.md',
  },
  localhost: {
    logoPath: 'favicon-brightdayerc',
    imagePath: 'img',
    backgroundColor: '#ffffff',
    color: '#000000',
    showLandingPage: true,
    title: 'Bright Day ERC',
    slogan: 'Optimized, Fast, Reliable',
    homeMd: 'home-brightDayErc.md',
  },
  'serverless-saas-skyrocket-8soxm8s79-peteristhegreat.vercel.app': {
    // for previews on vercel.com
    logoPath: 'favicon-brightdayerc',
    imagePath: 'img',
    backgroundColor: '#ffffff',
    color: '#000000',
    showLandingPage: true,
    title: 'Bright Day ERC',
    slogan: 'Optimized, Fast, Reliable',
    homeMd: 'home-brightDayErc.md',
  },
  // localhost: {
  //   logoPath: 'favicon-localhost',
  //   imagePath: 'img',
  //   backgroundColor: '#ffffff',
  //   color: '#000000',
  //   showLandingPage: true,
  //   title: 'Localhost ERC',
  //   slogan: 'Optimized, Fast, Reliable',
  //   homeMd: 'home-localhost.md',
  // },
};

export default domainNameOptions;
